.LandingPage header {
  margin: 50px 10px 0px 10px;
}

.LandingPage .h1-background {
  border-radius: 10px;
  background-image: url(../../board-background2.jpg);
}

.LandingPage h1 {
  font-size: 1.8em;
  color: black;
  background-color: rgba(7, 135, 135, .8);
  border-radius: 10px;
  border: solid black 2px;
}

.LandingPage .description-background {
  border-radius: 15px;
  margin: 0 5px;
  padding: 0px;
  background-image: url(../../board-background2.jpg);
  /* background-size: cover; */
}

.LandingPage .description {
  background-color: rgb(136, 7, 7, .8);
  color: white;
  border-radius: 15px;
  padding: 3px;
  border: solid 2px black;
}

.LandingPage button {
  margin-top: 20px;
  /* background-color: white;
  border: 1px solid black;
  border-radius: 15%;
  font-size: 16px;
  height: 60px;
  width: 60px;
  padding: 0;
  font-weight: bold; */
}

.LandingPage .landing-buttons {
  display: flex;
  justify-content: space-around;
}

@media screen and (min-width: 505px) {
  .LandingPage header {
    margin: 5vh auto 5vh auto;
    width: 500px;
  }
  .LandingPage .description-background {
    margin-left: 10vw !important;
    margin-right: 10vw !important;
  }
  .LandingPage .landing-buttons {
    margin-top: 5vh;
    margin-right: 20vw;
    margin-left: 20vw;
  }
}

@media screen and (min-width: 808px) {
  .LandingPage header {
    margin: 10vh auto 10vh auto;
    width: 500px;
  }
  .LandingPage .landing-buttons {
    margin-top: 10vh;
  }
}