* {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans",
  sans-serif; 
  font-size: 20px;
  text-align: center;
  line-height: 1.5;
  background-image: url(../../table-background.jpg);
}

.die {
  background-color: white;
  border: 1px solid black;
  border-radius: 15%;
  font-size: 16px;
  height: 60px;
  width: 60px;
  padding: 0;
  font-weight: bold;
}

button:hover, a:hover {
  transform: scale(1.2);
}

@media screen and (min-width: 505px) {
  body {
    font-size: 24px;
  }
  .die {
    height: 80px;
    width: 80px;
    font-size: .9em;
  }
}

@media screen and (min-width: 808px) {

}
